<template>
    <div v-if="Object.keys(detail).length">
        <div class="d-flex flex-wrap flex-stack my-5">
            <h2 class="fs-2 my-2">
                <router-link :to="'/ecommerce/user?userID=' + detail.user.id" class="cursor-pointer text-gray-900 text-hover-primary" target="_blank">
                    <span class="fw-bolder">{{ detail.user.full_name }}</span>
                </router-link>
                -
                <router-link :to="'/module/exam-management/exam/save/' + detail.exam.id" class="cursor-pointer text-gray-900 text-hover-primary" target="_blank">
                    <span class="fw-bolder">{{resolveDatum(detail.exam.translations, $root.defaultLanguage.id, 'language_id').title}}</span>
                </router-link>
                <span class="text-gray-800"> ({{ $moment(detail.result.user_session.session.session_date).format("DD.MM.YYYY") + ' ' + detail.result.user_session.session.session_time }})</span>
            </h2>
        </div>

        <div class="mt-14">
            <div class="question-informations d-flex w-100 flex-wrap">
                <div class="mb-4 me-16">
                    <div class="fs-5 fw-bold mb-1 text-gray-800">{{ $t('pages.module.examManagement.exam.result.detail.questionInfo.questionCount') }}</div>
                    <div class="text-gray-600 fw-bold fs-6">{{ detail.result.total }}</div>
                </div>
                <div class="mb-4 me-16">
                    <div class="fs-5 fw-bold mb-1 text-gray-800">{{ $t('pages.module.examManagement.exam.result.detail.questionInfo.time') }}</div>
                    <div class="text-gray-600 fw-bold fs-6">{{ detail.exam.exam_duration }}</div>
                </div>
                <div class="mb-4 me-16">
                    <div class="fs-5 fw-bold mb-1 text-gray-800 correct-info">{{ $t('common.true') }}</div>
                    <div class="text-gray-600 fs-6"><span class="fw-bold">{{ detail.result.correct }}</span>/{{ detail.result.total }}</div>
                </div>
                <div class="mb-4 me-16">
                    <div class="fs-5 fw-bold mb-1 text-gray-800 wrong-info">{{ $t('common.false') }}</div>
                    <div class="text-gray-600 fs-6"><span class="fw-bold">{{ detail.result.wrong }}</span>/{{ detail.result.total }}</div>
                </div>
                <div class="mb-4 me-16">
                    <div class="fs-5 fw-bold mb-1 text-gray-800 empty-info">{{ $t('common.empty') }}</div>
                    <div class="text-gray-600 fs-6"><span class="fw-bold">{{ detail.result.unanswered }}</span>/{{ detail.result.total }}</div>
                </div>
                <div class="mb-4 me-16">
                    <div class="fs-5 fw-bold mb-1 text-gray-800">{{ $t('pages.module.examManagement.exam.result.detail.questionInfo.grade') }}</div>
                    <div class="text-gray-700 fw-bold fs-6">{{ parseFloat(detail.result.grade) }}</div>
                </div>
                <div class="mb-4 me-16">
                    <div class="fs-5 fw-bold mb-1 text-gray-800">{{ $t('pages.module.examManagement.exam.result.detail.questionInfo.spendedTime') }}</div>
                    <div class="text-gray-700 fw-bold fs-6">{{ getSpendedTime(detail.result.spended_time) }}</div>
                </div>
            </div>
            <ul class="nav nav-tabs border-bottom-0 fw-bolder fs-6 question-tab-menu mt-7">
                <li class="nav-item">
                    <button class="nav-link text-gray-700 fw-bolder" v-on:click="changedQuestionStatus('all')" :class="{'active' : filter.question == 'all'}">
                        {{ $t('common.viewAll') }} ({{ detail.result.total }})
                    </button>
                </li>
                <li class="nav-item">
                    <button class="nav-link text-gray-700 fw-bolder" v-on:click="changedQuestionStatus('correct')" :class="{'active' : filter.question == 'correct'}">
                        {{ $t('common.true') }} <span class="text-success">({{ detail.result.correct }})</span>
                    </button>
                </li>
                <li class="nav-item">
                    <button class="nav-link text-gray-700 fw-bolder" v-on:click="changedQuestionStatus('wrong')" :class="{'active' : filter.question == 'wrong'}">
                        {{ $t('common.false') }} <span class="text-danger">({{ detail.result.wrong }})</span>
                    </button>
                </li>
                <li class="nav-item">
                    <button class="nav-link text-gray-700 fw-bolder" v-on:click="changedQuestionStatus('empty')" :class="{'active' : filter.question == 'empty'}">
                        {{ $t('common.empty') }} <span class="text-dark">({{ detail.result.unanswered }})</span>
                    </button>
                </li>
            </ul>

            <div class="mt-5" v-if="questions.length">
                <div class="question-numbers py-5">
                    <a class="btn btn-hover-rise cursor-pointer" v-for="(question, questionIndex) in questions" :key="questionIndex" :class="[('btn-' + colorClasses.question[question.user_answer_status])]" v-on:click="showQuestionIndex = questionIndex">{{ question.number }}</a>
                </div>
                <div class="separator separator-dashed my-3 border-gray-400"></div>
                <div class="question-box mt-12">
                    <div class="question-detail">
                        <div class="question-detail-header mb-3">
                            <div class="question-number">
                                <a class="btn btn-sm cursor-pointer" :class="[('btn-' + colorClasses.question[currentQuestion.user_answer_status])]">{{currentQuestion.number}}</a>
                            </div>
                            <!--<div class="question-category"> Bu bir <span class="text-primary fw-500">Dilbilgisi ve Edat Bilgisi</span> sorusudur. </div>-->
                        </div>
                        <div class="question-detail-body" v-html="resolveDatum(currentQuestion.translations, $root.defaultLanguage.id, 'language_id').content"></div>
                    </div>
                    <div class="question-options mt-12" v-if="currentQuestion.answers && currentQuestion.answers.length">
                        <div class="alert d-flex align-items-center p-5 mb-5" v-for="(answer, answerIndex) in currentQuestion.answers" :key="answerIndex" :class="[('alert-' + colorClasses.answer[answer.type].background)]">
                            <span class="badge option-badge fw-bolder fs-2 me-4" v-if="currentQuestion.type_id != 3">{{ $root.questionLetters[answerIndex]}}</span>
                            <div class="d-flex flex-column w-100 text-center">
                                <div class="fw-bolder fs-4 option-content" :class="[('text-' + colorClasses.answer[answer.type].color)]" v-html="resolveDatum(answer.translations, $root.defaultLanguage.id, 'language_id').content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "detail",
    data() {
        return {
            detail: {},
            filter: {
                question: "all"
            },
            colorClasses: {
                question: {
                    correct: "success",
                    wrong: "danger",
                    empty: "secondary"
                },
                answer: {
                    correct: {background: "success", color: "success"},
                    wrong: {background: "danger", color: "danger"},
                    default: {background: "white", color: "dark"}
                }
            },
            showQuestionIndex: 0
        }
    },
    computed: {
        resultID() {
            return this.$route.params.id;
        },
        questions() {
            let questions = [];

            this.detail.questions.forEach((question, questionIndex) => {
                question.number = (questionIndex + 1);
                if (this.filter.question == 'all' || question.user_answer_status == this.filter.question) {
                    questions.push(question);
                }
            })

            return questions;
        },
        currentQuestion(){
            let currentQuestion = {};

            if(this.questions[this.showQuestionIndex]){
                currentQuestion = this.cloneData(this.questions[this.showQuestionIndex]);
                let foundAnswered = this.detail.answers.filter(answer => answer.question_id == currentQuestion.id);

                if(currentQuestion.answers && currentQuestion.answers.length) {
                    currentQuestion.answers = currentQuestion.answers.map((answer) => {
                        answer.type = 'default';

                        if (answer.id == currentQuestion.correct_answer_id) {
                            answer.type = 'correct';
                        } else if (foundAnswered.length == 1 && foundAnswered[0].answer_id == answer.id) {
                            answer.type = 'wrong';
                        }

                        return answer;
                    });
                }
            }

            return currentQuestion;
        },
    },
    created() {
        if (this.resultID && !(this.resultID > 0)) {
            this.$router.push({
                path: "/module/exam-management/exam/result"
            });
        }
    },
    mounted() {
        if (this.resultID && this.resultID > 0) {
            this.loadResult();
        }
    },
    methods: {
        loadResult() {
            this.axios.get(this.endpoints['module_exam_result'] + '/' + this.resultID).then((response) => {
                let data = response.data.data;
                this.detail = data;
            })
        },
        changedQuestionStatus(state) {
            this.filter.question = state;
            this.showQuestionIndex = 0;
        },

        getSpendedTime(spendedTime){
            let time = this.$moment.utc(spendedTime*1000);
            let timeParts = {hour: {format: 'HH'}, minute: {format: 'mm'}, second: {format: 'ss'}};
            let timeText = [];

            for(let key in timeParts){
                let value = parseInt(time.format(timeParts[key].format));
                timeParts[key].value = value;
                timeParts[key].text = this.sprintf('%d %s', [value, this.$t('common.' + key).toLowerCase()]);
            }

            if(timeParts.hour.value > 0){
                timeText = [timeParts.hour.text, timeParts.minute.text, timeParts.second.text];
            } else if(timeParts.minute.value > 0) {
                timeText = [timeParts.minute.text, timeParts.second.text];
            } else {
                timeText = [timeParts.second.text]
            }

            return timeText.join(', ');
        }
    }
}
</script>

<style>
.question-tab-menu .nav-link {
    border-radius: 0.475rem !important;
    border-color: transparent !important;
}

.question-tab-menu .nav-link.active {
    border-color: #EFF2F5 !important;
}

.question-numbers {
    overflow-x: auto;
    white-space: nowrap;
}

.question-numbers .btn, .question-number .btn {
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    margin-left: 7px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.question-numbers .btn:first-of-type {
    margin-left: 0;
}

.question-box .question-detail {
    background-color: #FFFFFF;
    border-radius: 0.475rem;
    position: relative;
    padding: 2rem 1.25rem 0.75rem;
}

.question-box .question-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question-box .question-detail-header .question-number {
    position: absolute;
    left: 10px;
    top: -15px !important;
    margin-bottom: 0;
}

.question-box .question-detail-header .question-number .btn {
    max-width: 35px;
    min-width: 35px;
    height: 35px;
}

.question-box .question-detail-header .question-category {
    background-color: rgba(113, 123, 150, 0.1);
    padding: 0.75rem 1.2rem;
    border-radius: 8px;
    font-size: .8rem;
    margin-left: auto;
    margin-top: -10px;
}

.question-box .question-options .option-badge {
    background-color: #50525b;
    color: #FFFFFF;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.question-box .question-options .option-content img{
    width: 180px !important;
    height: 90px !important;
    object-fit: cover !important;
}

.question-box .question-options .option-content > p:last-of-type {
    margin-bottom: 0 !important;
}
.question-informations .correct-info, .question-informations .wrong-info, .question-informations .empty-info {
    display: flex;
    align-items: center;
}

.question-informations .correct-info:after, .question-informations .wrong-info:after, .question-informations .empty-info:after {
    content: "";
    display: block;
    max-width: 9px;
    min-width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 1rem;
}

.question-informations .correct-info:after{
    background-color: rgba(49, 184, 87, 0.8);
}

.question-informations .wrong-info:after{
    background-color: rgba(189, 26, 32, 0.8);
}

.question-informations .empty-info:after{
    background-color: rgba(60, 72, 88, 0.8);
}

@media (min-width: 1024px) {
    .question-box .question-options {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
}

</style>